<template>

    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

        <b-card no-body>
            <b-card-body>
                <b-row>

                    <b-col cols="12" md="4" class="mb-md-0  mobile_tab_max_width_flex">
                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sites"
                            label="site_name"
                            class="w-100 p-2"
                            placeholder="Project Site"
                            v-model="siteData"
                            @input="filterTable"
                            :clearable="false"
                        >
                            <template #selected-option="{ site_name }">
                                <span v-if="site_name.length < 23">{{site_name}}</span>
                                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '..'}}</span>
                            </template>
                        </v-select>
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="8" class="mobile_tab_max_width_flex all_btn_tab">
                        <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                                placeholder="Search..."
                                @input="filterTable"
                            />
                            
                            <b-button
                                variant="danger" 
                                :to="{name:'break-hours'}"
                                class="mobile-margin-bottom"
                                v-if="checkPermission($route.name, 'Manage Break Hours')"
                            >
                                <span class="text-nowrap">Manage Break Hours</span>
                            </b-button>

                            <b-button
                                variant="warning"
                                class="ml-1" 
                                v-if="this.siteData != null && typeof this.siteData.group_filter != 'undefined' && this.siteData.group_filter == 'yes' && checkPermission($route.name, 'Add')"
                                :to="{name:'add-task-group', params: { site_id: this.siteData ? this.siteData._id : null }}"
                            >
                                <span class="text-nowrap">Add</span>
                            </b-button>
                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative sTable"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
                :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
            >

                <template #cell(site)="items">
                    <span class="wordBreak">{{ items.item.site.site_name | capitalize}}</span>
                </template>   

                <template #cell(name)="items">
                    <span class="wordBreak">{{ items.item.name | capitalize}}</span>
                </template>

                <!-- <template #cell(break)="items">
                    <span class="wordBreak" v-for="(breakData,index) in items.item.break" v-if="items.item.break.length > 0">
                      {{index+1 +'- '+ breakData.duration}}
                    </span>
                    <span v-if="items.item.break.length == 0">N/A</span>
                </template> -->

                <template #cell(set_name)="items">
                    
                    <span class="wordBreak" v-if="items.item.set_id != null">{{items.item.set_id.set_name | capitalize}}</span>
                    <span class="wordBreak" v-if="items.item.set_id == null">{{'N/A'}}</span>
                </template>                
                
                

                <template #cell(created_at)="items">
                    <span>{{sitedateTime(items.item.created_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</span>
                </template>

                <template #cell(actions)="items">
                    <!-- <b-dropdown
                        
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                        v-if="checkPermission($route.name, 'Edit') || checkPermission($route.name, 'Clear Break Hour(s)') || checkPermission($route.name, 'Delete Group')"
                    > -->
                        <!-- 3 dot menu -->
                        <!-- <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template> -->

                        <!-- Edit button -->
                        <!-- <b-dropdown-item :to="{ name: 'edit-task-group', params: { id: items.item._id } }" v-if="checkPermission($route.name, 'Edit')">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item> -->

                        <!-- Change Status button -->

                        <!-- Remove Break Hour button -->
                        <!-- <b-dropdown-item  @click="removeBreakHours(items.item._id)" v-if="checkPermission($route.name, 'Clear Break Hour(s)')">
                            <feather-icon icon="XIcon" />
                            <span class="align-middle ml-50">Clear Break Hour(s)</span>
                        </b-dropdown-item> -->

                        <!-- Delete button -->
                        <!-- <b-dropdown-item  @click="deleteItem(items.item)" v-if="checkPermission($route.name, 'Delete Group')">
                            <feather-icon icon="Trash2Icon" />
                            <span class="align-middle ml-50">Delete Group</span>
                        </b-dropdown-item> -->

                    <!-- </b-dropdown>  -->
                    
                    <b-link  :to="{ name: 'edit-task-group', params: { id: items.item._id } }" v-if="checkPermission($route.name, 'Edit')"
                        v-b-tooltip.hover.v-warning
                        title="Edit"
                        variant="outline-warning"
                        >
                        <feather-icon icon="EditIcon" class="mediumSize mr-1"/>  
                    </b-link>

                    <b-link  @click="removeBreakHours(items.item._id)" v-if="checkPermission($route.name, 'Clear Break Hour(s)')"
                        v-b-tooltip.hover.v-warning
                        title="Clear Break Hour(s)"
                        variant="outline-warning"
                        >
                        <feather-icon icon="XIcon" class="mediumSize mr-1"/>  
                    </b-link>

                     <b-link  @click="deleteItem(items.item)" v-if="checkPermission($route.name, 'Delete Group')"
                        v-b-tooltip.hover.v-warning
                        title="Delete Group"
                        variant="outline-warning"
                        >
                        <feather-icon icon="Trash2Icon" class="mediumSize"/>  
                    </b-link>
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>
    </div>
</template>

<script>
    import {
        BCard, VBTooltip, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { POST_API } from "../../../store/actions.type"
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect,BBreadcrumb
        },
        directives: {
            Ripple,
            'b-tooltip': VBTooltip
        },
        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 

                tableColumns: [
                    { key: 'site', label: 'Site Name', sortable: true , thStyle:  {width: '25%'}},
                    { key: 'name', label: 'Group Name', sortable: true , thStyle:  {width: '24%'}},
                    { key: 'set_name', label: 'Set Name', sortable: false , thStyle:  {width: '17%'}},
                    { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '18%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
                ],
                
                // b-table and pagination options
                items           : [],
                isSortDirDesc   : true,
                perPageOptions  : [10, 20, 50, 100],
                perPage         : 10,
                sortBy          : 'id',
                totalRecords    : 0,
                currentPage     : 1,
                searchQuery     : '',
                from            : null,
                to              : null, 
                
                // Project site vars
                sites:[],
                siteData:null,  
            }
        },

        methods : {
            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,
                        site          : this.siteData ? this.siteData._id : null,
                        role          : this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/task-group-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },

            filterTable(){
                this.$refs.refUserListTable.refresh();
            },

            /*variantColor(status){
                if (status == 'active') {
                    return 'success';
                }else{
                    return 'primary';
                }
            },

            statusChange(item){
                if (item.status == 'active') {
                    var msg     = 'Are you sure want to inactivate this record?';
                } else{
                    var msg     = 'Are you sure want to activate this record?';
                }

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : item._id,
                                status : item.status
                            },
                            api : "/api/change-equipment-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                this.successAlert();
                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },*/

            removeBreakHours(id){
                var msg = 'Are you sure want to remove break hours of this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id : id
                            },
                            api : "/api/remove-break-hours-task-group",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert();

                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            deleteItem(item){
                var msg = 'Are you sure want to delete this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                name     : item.name,
                                site : item.site
                            },
                            api : "/api/unassigned-task-group",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert();

                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                    role:this.$store.getters.currentUser.role,
                    om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.sites = this.$store.getters.getResults.data;
                        // if (this.sites.length == 1 ) {
                        //     this.siteData = this.sites[0];
                        //     this.filterTable();
                        // }
                        var obj = {
                            _id:'all-site',
                            site_name:'All Project Sites',
                            pdf_date_format: 'DD MMM YYYY',
                            pdf_time_format: 'HH:mm'
                        }
                        this.sites.unshift(obj);

                        this.siteData = this.defaultSite(this.sites);
                        this.filterTable();
                        
                        return this.sites;
                    }
                });
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:null,
                text: 'Schedule Work'
              },{
                to:null,
                text: 'Group Setting',
                active:true
              }];
              return item;
            }

        },
        mounted(){
            this.allSites();
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
